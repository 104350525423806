<template>
  <div class="pass-word">
    <a-form-model
      class="pass-wrap"
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="Old password" prop="password" v-if="!hidePass">
        <a-input v-model.trim="ruleForm.password" type="password" @blur="() => passwordError = false" placeholder="Please enter"/>
      </a-form-model-item>
      <a-form-model-item label="New Password" prop="newPassword">
        <a-input v-model="ruleForm.newPassword" type="password" placeholder="Please enter"/>
        <div style="line-height: 1.5">
          The password length must be greater than or equal to 8 characters, including letters, numbers and special characters
        </div>
      </a-form-model-item>
      <a-form-model-item label="Confirm password again" prop="comfirmPassword">
        <a-input v-model="ruleForm.comfirmPassword" type="password" placeholder="Please enter" @paste.native.capture.prevent="() => { return false }" />
      </a-form-model-item>
      <a-form-model-item label=" " style="text-align: center" :colon="false">
        <a-button type="primary" @click="handleSubmit" :loading="loading">
          Confirm
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  props: {
    hidePass: {
      type: Boolean,
      default: false
    },
    inputPass: {
      type: String,
      default: '123456'
    }
  },
  data () {
    var checkPassword = (rule, value, callback) => {
      const reg1 = /[0-9].*/
      const reg2 = /[a-zA-Z].*/
      const reg3 = /[\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F].*/
      if (value === this.ruleForm.password) {
        callback(new Error('The new password cannot be the same as the old password.'))
      } else if (reg1.test(value) && reg2.test(value) && reg3.test(value)) {
        callback()
      } else {
        callback(new Error('The password length must be greater than or equal to 8 characters, including letters, numbers and special characters'))
      }
    }
    var checkComfirmPass = (rule, value, callback) => {
      if (value === this.ruleForm.newPassword) { 
        callback()
      } else { 
        callback(new Error('It is inconsistent with the password entered for the first time.'))
      }
    }
    var checkOldPassword = (rule, value, callback) => { 
      if (this.passwordError) {
        callback(new Error('Incorrect password'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
      passwordError: false,
      ruleForm: {
        password: '',
        newPassword: '',
        comfirmPassword: '',
        username: JSON.parse(localStorage.getItem('userInfo'))?.username
      },
      rules: {
        password: [
          { required: true, message: 'Please enter', trigger: 'blur' },
          {validator: checkOldPassword, trigger: 'blur'}
        ],
        newPassword: [
          { required: true, message: 'Please enter', trigger: 'blur' },
          { min: 8, message: 'The password length must be greater than or equal to 8 characters, including letters, numbers and special characters', trigger: 'blur' },
          {validator: checkPassword, trigger: 'blur'}
        ],
        comfirmPassword: [
          { required: true, message: 'Please enter', trigger: 'blur' },
          {validator: checkComfirmPass, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true

            const password = this.ruleForm.password ? this.ruleForm.password : this.inputPass

            const res = await this.$http.post('/platform/admin/ticketAdminUser/v1/changePassword', {...this.ruleForm, password })
            if (res.code === 0) {
              this.$message.success('Password modified successfully');
              this.$emit('handleSuccess')
              this.$refs.ruleForm.resetFields();
            }
          } catch (error) {
            if (error.code === 410005 && !this.hidePass) {
              this.passwordError = true
              setTimeout(() => {
                this.$refs.ruleForm.validateField('password')
              }, 1000)
            }
          } finally { 
            this.loading = false
          }
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.pass-wrap{
  width: 600px;
  margin: 0px auto;
}
</style>