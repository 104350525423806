<template>
  <a-modal
    :title="title"
    :visible="visible"
    :footer="null"
    :destroyOnClose="true"
    :closable="false"
    width="620px"
  >
  <Password :hidePass="hidePass" :inputPass="inputPass" @handleSuccess="handleSuccess"/>
  </a-modal>
</template>

<script>
import Password from '../../pages/password/components/Password.vue'
export default {
  props: {
    inputPass: {
      type: String,
      default: '123456'
    }
  },
  components: { Password },
  data () { 
    return {
      visible: false,
      hidePass: false,
      title: 'For the first login, please set the password!'
    }
  },
  methods: {
    show (title, hidePass) {
      console.log(title, hidePass)
      this.title = title
      this.hidePass = hidePass
      this.visible = true
    },
    handleSuccess () {
      this.$emit('handleSuccess')
      this.visible = false
    }
  }
}
</script>