<template>
  <div class="login-page">
    <div class="form-block">
      <a-form-model
        ref="form" 
        :model="loginForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        :rules="rules"
        labelAlign="right">
        <p class="title pt24 mb16 text-center">Customer service system</p>
        <a-form-model-item label="Account Name" prop="username">
          <a-input placeholder="please enter" v-model="loginForm.username" size="large"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="password" label="Password">
          <a-input-password placeholder="please enter" v-model="loginForm.password" show-password size="large"></a-input-password>
        </a-form-model-item>
        <div class="opr">
          <a-button type="primary" size="large" class="left" @click="login" :loading="loading">login</a-button>
          <a-button @click="onReset" size="large" class="right">reset</a-button>
        </div>
      </a-form-model>
    </div>
    <first-login-modal @handleSuccess="handleSuccess" ref="firstLogin" :inputPass="loginForm.password"></first-login-modal>
  </div>
</template>

<script>
import FirstLoginModal from './components/FirstLoginModal.vue'
import { Modal } from 'ant-design-vue';

export default {
  components: { 
    FirstLoginModal
  },
  name: "Login",
    data() {
      return {
        role: null,
        loading: false,
        loginForm: {
          username: '',
          password: ''
        },
        rules: {
          username: [{ required: true, message: 'please enter the account name', trigger: 'blur'}],
          password: [{ required: true, message: 'please enter the password', trigger: 'blur' }]
        }
      }
    },
    methods: {
      async login () {
        try {
          const valid = await this.$refs.form.validate()
          if (!valid) return
          this.loading = true
          this.$http.post('/platform/admin/ticketAdminUser/v1/login', {
            ...this.loginForm
          }).then(res => {
            this.role = res.data.role
            localStorage.setItem('userInfo', JSON.stringify({
              ...res.data, // id role email
              token: undefined
            }))
            localStorage.setItem('token', res.data.token)

            // 首次登录重新设置密码
            if (!res.data.lastLoginTime) {
              this.$refs.firstLogin.show('For the first login, please set the password!', true)
              return
            }

            // 初始化密码
            if (res.data.isInitialPassword) {
              this.$refs.firstLogin.show('Please set the password！', true)
              return
            }

            // 密码过期
            if (res.data.passwordExpire) { 
              this.$refs.firstLogin.show('Please change the password!', false)
              return
            }
            this.$message.success('login success')
            const path = res.data.role === 1 ? '/ship' : '/ticket/list'
            this.$router.push(path)
          }).catch((error) => {
            if (error.code === 430008) { 
              this.$message.error('User does not exist.')
            } else { 
              this.handleErrorModal(error.code)
            }
          }).finally(() => {
            this.loading = false
          })
        } catch (error) {
          // 
        }
      },
      // 错误处理
      handleErrorModal (code, title = 'Warning') {
        const text = {
            '430015': 'Your account has been disabled, please contact the administrator!',
            '410004': 'You have entered the wrong password for 5 consecutive times. Login has been restricted. It will take 24 hours to remove the restriction.',
            '410003': 'You have entered the wrong password 3 times in a row, and your account will be locked after 5 times in total, and you need to wait 24 hours to log in.'
          }
        const content = text[code]
        if (!content) return
        Modal.warning({
          cancelText: 'Ok',
          title: () => title,
          content
        })
      },
      handleSuccess () { 
        const path = this.role === 1 ?  '/ship' : '/ticket/list'
        this.$router.push(path)
      },
      onReset() {
        this.loginForm = { ...this.$options.data().loginForm }
      }
    }
  }
</script>

<style scoped lang="less">
  .login-page {
    background: url("../assets/img/login_background.jpg");
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .form-block{
    width: 500px;
    height: 300px;
    margin: calc(50vh - 150px) auto 0 auto;
    border-radius: 20px;
    background: rgba(240,247,255,0.85);
    .title {
      font-weight: 700;
      font-size: 3ch;
    }
    .opr{
      width: 180px;
      height: 50px;
      margin: 0 auto;
      .left{
        float: left;
      }
      .right{
        float: right;
      }
    }
  }
  
</style>